@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Grandstander&display=swap'); */

body {
  /* font-family: 'Grandstander', cursive; */
  font-family: 'Montserrat', sans-serif;
}

.video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.stacking-slide-video {
  height: 100vh;
  width: 100%;
  top: 0;
}
/* 
.stacking-slide {
  height: 70vh;
  width: 100%;
  top: 0;
}

.stacking-slide-low {
  height: 800px;
  width: 100%;
  top: 0;
}
 */
.underline {
  text-decoration: none;
  position: relative;
}

.underline::after {
    position: relative;
        content: ' ';
        height: 4px;
		  /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
        top: -4px; 

       /****** 
       optional values below 
       ******/
        
        /* center - (optional) use with adjusting width   */
        margin: 0 auto;
		  left: 0;
        right: 0;
		  width:100%;
		  background: green;
		  
		  /* optional animation */
    transition:.5s;
}

.upperline::after {
  position: absolute;
      content: '';
      height: 4px;
    /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
      top: 4px; 

     /****** 
     optional values below 
     ******/
      
      /* center - (optional) use with adjusting width   */
      margin: 0 auto;
    left: 0;
      right: 0;
    width:100%;
    background: green;
    
    /* optional animation */
    transition:.5s;
}

.overlay {
  background: rgba(0,0,0,0);
  position: relative;
  display: flex;
  justify-content: center;
}

a.button1 {
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #000000;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:600;
  color:#000000;
  text-align:center;
  transition: all 0.2s;
  font-size: 30px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  }

  .textsection {
    font-size: 3.5vw;
  }

  a.button1:hover{
    color:#FFFFFF;
    background-color:#000000;
    }

    @media screen and (max-width: 800px) {
      
      .textsection {
        padding: 40px 0px;
        font-size: 6vw;
      }

      a.button1 {
        font-size: 30px;
      }

      
    }
